import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'
import Section from '../components/Section'
import Seo from '../components/Seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title="Pagina niet gevonden" />
    <Container>
      <Section>
        <h1>Pagina niet gevonden</h1>
        <p>We kunnen de opgevraagde pagina helaas niet vinden.</p>
      </Section>
    </Container>
  </Layout>
)

export default NotFoundPage
